@import '../node_modules/normalize.css/normalize.css';
@import '../node_modules/typeface-lato/index.css';

:root {
    --app-bg: #bababa;
}

body {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    width: 100%;
    height: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 2rem;
    font-weight: 300;
}

.greeting {
    margin: 0.5rem 0;
    font-size: 5rem;
    font-weight: inherit;
}

.tag {
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: bolder;
    text-align: center;
    background-color: #E72449;
    color: white;
    padding: 1rem;
    border-radius: 10px;
}

.description {
    margin: 0.5rem 0;
    font-size: 1.5rem;
    font-weight: inherit;
    text-align: center;
}

.contact {
    font-size: 1.2rem;
    margin: 0;
    letter-spacing: 0.05rem;
    text-align: center;
}

.hidden {
    display: none;
}

::selection {
    background: var(--app-bg);
}
